@import '../styles/constants.module.scss';

.logoImg {
  width: 260px;
  height: 46px;
  @media screen and (max-width: $mobile) {
    width: 236px;
    height: 42px;
  }
}
