//@import '~bulma/sass/grid/columns';
@import './constants.module.scss';

$navbar-breakpoint: 100000px;
.navbar-menu {
  width: min(600px, 100%);
  position: absolute;
  right: 0;
}

//$control-radius: 12px;
$input-radius: 50px;
$input-border-color: $primaryBackground;
$label-weight: 500;
//$label-color: #555555;

$box-background-color: $primaryBackground;
$box-color: #000;
$box-padding: 30px;
$box-radius: 24px;
$box-shadow: none;
$tabs-toggle-link-border-color: $primaryBackground;
$tabs-toggle-link-hover-border-color: $primaryBackground;
$tabs-toggle-link-active-color: #000;
$tabs-toggle-link-active-border-color: $primaryBackground;
$tabs-toggle-link-active-background-color: $primaryBackground;

@import '~bulma/bulma';

html,
body {
  padding: 0;
  margin: 0;
  //font-family: 'Inter';
  font-style: normal;
  //font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
  //  Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #ffffff;
  color: #000000;
  height: 100%;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
