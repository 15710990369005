$desktop: 1460px;
$medium: calc($desktop * 3 / 4);
$tablet: calc($desktop / 2);
$mobile: 600px;
$mobileMinWidth: 373px;
$desktopInterestButtonWidth: 350px;
$selectedInterestBorderColor: #000;
$primaryBackground: #f9f9f9;
$label-color: #555;

$color0OfDiagramArc: #ffdcc4;
$secondaryColor0: #ffe6d6;
$backgroundColor0: #fff6f1;
$primaryColor0: #d17c31;

$color1OfDiagramArc: #cce5ff;
$secondaryColor1: #e3f1ff;
$backgroundColor1: #f4f9ff;
$primaryColor1: #4197d5;

$color2OfDiagramArc: #b1f8e0;
$secondaryColor2: #ccffee;
$backgroundColor2: #edfff8;
$primaryColor2: #19a381;

$color3OfDiagramArc: #bdf5b2;
$secondaryColor3: #dbffd3;
$backgroundColor3: #f0ffec;
$primaryColor3: #5fa055;

$color4OfDiagramArc: #ffd9e2;
$secondaryColor4: #ffebf0;
$backgroundColor4: #fff7f8;
$primaryColor4: #d57192;

$color5OfDiagramArc: #d4b6fb;

$color6OfDiagramArc: #fbb6b6;

$color7OfDiagramArc: #fbe7a0;

$color8OfDiagramArc: #a0e5fb;

$color9OfDiagramArc: #fff79e;
$secondaryColor9: #fff9bf;
$backgroundColor9: #fffcde;
$primaryColor9: #d2ba62;

$color10OfDiagramArc: #cacefa;
$secondaryColor10: #e0e3ff;
$backgroundColor10: #eeedff;
$primaryColor10: #8365da;

$color11OfDiagramArc: #cdf4fa;
$secondaryColor11: #e7fcff;
$backgroundColor11: #f6ffff;
$primaryColor11: #71bdd5;

$color12OfDiagramArc: #ffa500;
$color13OfDiagramArc: #89f989;

$numberOfColors: 14;
:export {
  primaryBackground: $primaryBackground;
  desktopWidth: $desktop;
  mobileWidth: $mobile;
  mobileMinWidth: $mobileMinWidth;
  desktopInterestButtonWidth: $desktopInterestButtonWidth;
  color0OfDiagramArc: $color0OfDiagramArc;
  secondaryColor0: $secondaryColor0;
  backgroundColor0: $backgroundColor0;
  primaryColor0: $primaryColor0;
  color1OfDiagramArc: $color1OfDiagramArc;
  secondaryColor1: $secondaryColor1;
  backgroundColor1: $backgroundColor1;
  primaryColor1: $primaryColor1;
  color2OfDiagramArc: $color2OfDiagramArc;
  secondaryColor2: $secondaryColor2;
  backgroundColor2: $backgroundColor2;
  primaryColor2: $primaryColor2;
  color3OfDiagramArc: $color3OfDiagramArc;
  secondaryColor3: $secondaryColor3;
  backgroundColor3: $backgroundColor3;
  primaryColor3: $primaryColor3;
  color4OfDiagramArc: $color4OfDiagramArc;
  secondaryColor4: $secondaryColor4;
  backgroundColor4: $backgroundColor4;
  primaryColor4: $primaryColor4;
  color5OfDiagramArc: $color5OfDiagramArc;
  color6OfDiagramArc: $color6OfDiagramArc;
  color7OfDiagramArc: $color7OfDiagramArc;
  color8OfDiagramArc: $color8OfDiagramArc;
  color9OfDiagramArc: $color9OfDiagramArc;
  secondaryColor9: $secondaryColor9;
  backgroundColor9: $backgroundColor9;
  primaryColor9: $primaryColor9;
  color10OfDiagramArc: $color10OfDiagramArc;
  secondaryColor10: $secondaryColor10;
  backgroundColor10: $backgroundColor10;
  primaryColor10: $primaryColor10;
  color11OfDiagramArc: $color11OfDiagramArc;
  secondaryColor11: $secondaryColor11;
  backgroundColor11: $backgroundColor11;
  primaryColor11: $primaryColor11;
  color12OfDiagramArc: $color12OfDiagramArc;
  color13OfDiagramArc: $color13OfDiagramArc;
  numberOfColors: $numberOfColors;
}
:export {
  desktop: $desktop;
  medium: $medium;
  tablet: $tablet;
  mobile: $mobile;
}
